import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURL, dbURLists, dbURListsRefs, customStyles2 } from '../Constants';
import '../lists.scss';
import SEO from '../../SEO';
import { animateScroll as scroll } from "react-scroll";
import {DebounceInput} from 'react-debounce-input';
import { AgGridReact } from 'ag-grid-react';
import ReactMarkdown from 'react-markdown';
import { useParams, Link } from 'react-router-dom';
import ReactPlayer from "react-player";
//import Highlighter from "react-highlight-words";
import ReadMoreAndLess from "react-read-more-less";
import { SearchLists, SelectProperty, DecadeArrayChoice } from "../misc/utils";
import Select from 'react-select';

const AlbumLists = (props) => {
    const [albums, setAlbums] = useState([])
    const [list, setList] = useState([])
    const [references, setReferences] = useState([])
    const [loadingAlbums, setLoadingAlbums] = useState(true)
    const [loadingList, setLoadingList] = useState(true)
    const [errorAlbums, setErrorAlbums] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [errorReferences, setErrorReferences] = useState(null)
    const [optionArtist, setOptionArtist] = useState(null)
    const [optionSimpleArtist, setOptionSimpleArtist] = useState(null)
    const [optionYear, setOptionYear] = useState(null)
    const [optionSource, setOptionSource] = useState(null)
    const [optionLabel, setOptionLabel] = useState(null)
    const [searchString, setSearchString] = useState('')
    //const [searchStringTracks, setSearchStringTracks] = useState('')
    const [optionRange, setOptionRange] = useState('1-100')
    const [decadeRange, setDecadeRange] = useState(null)
    const [AgGrid, setAgGrid] = useState(false)
    const [columnDefs] = useState([
        {headerName: "#", field: "rank", resizable: true, width: 50, cellStyle: { 'font-size': '14px' }}, 
        {headerName: "Album", field: "title", resizable: true, width: 400, cellStyle: { 'font-size': '14px' }},
        {headerName: "Artist", field: "artist", filter: true, sortable: true, width: 300, cellStyle: { 'font-size': '14px' }},
        {headerName: "Year", field: "year", sortable: true, width: 70, cellStyle: { 'font-size': '14px' }}
      ])
    
    const params = useParams();

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbURLists + params.slug)
            .then(response => {
                setLoadingList(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchAlbums = () => {
            axios.get(dbURL + props.AlbumsAPI + params.slug)
            .then(response => {
                setLoadingAlbums(false)
                setAlbums(response.data)
            }).catch(errorAlbums => {
                setErrorAlbums(errorAlbums);
            });
        }

        const fetchReferences = () => {
            axios.get(dbURListsRefs + params.slug)
            .then(response => {
                setReferences(response.data)
            }).catch(errorReferences => {
                setErrorReferences(errorReferences);
            });
        }

        fetchList()
        fetchAlbums()
        fetchReferences()
      }, [params.slug, props.AlbumsAPI])
  
    if (errorList) return `Error List: ${errorList.message}`;
    if (errorAlbums) return `Error Albums: ${errorAlbums.message}`;
    if (errorReferences) return `Error References: ${errorReferences.message}`;

    if (loadingList || loadingAlbums) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    <Link to="/lists">
                                        Go to current Lists
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    let sortedAlbums, filterArray, ListPic, ListPic2, ArtistOptions, YearOptions, 
    LabelOptions, SourceOptions, sortedReferences, unranked, rangeArray, SimpleArtistOptions, 
    decadeArray

    if (props.AlbumsAPI.includes('unranked')) {
        unranked = true;
    }

    const {album_tracks_center,sort_ascending, hide_grid_option,
        name, description, picture_link, page, range_200, range_500, 
        markdown_personnel, simple_artist_select, comment3, honors_awards, 
        decade, album_covers} = list[0];

    ArtistOptions = SelectProperty(albums, 'artist')
    YearOptions = SelectProperty(albums, 'year')
    LabelOptions = SelectProperty(albums, 'label')
    SourceOptions = SelectProperty(albums, 'source')
    SimpleArtistOptions = SelectProperty(albums, 'simple_artist')

    //dersom decade må decadeRange må settes
    if (decade) {
        decadeArray = DecadeArrayChoice(decade)
        if (!decadeRange) {
        setDecadeRange(decadeArray[0])
        }
    }
    

    const selectedOptionArtist = (selectedArtist) => {
        setOptionArtist(selectedArtist);
    }

    const selectedOptionSimpleArtist = (selectedSimpleArtist) => {
        setOptionSimpleArtist(selectedSimpleArtist);
    }

    const selectedOptionYear = (selectedYear) => {
        setOptionYear(selectedYear);
    }

    const selectedOptionLabel = (selectedLabel) => {
        setOptionLabel(selectedLabel);
    }

    const selectedOptionSource = (selectedSource) => {
        setOptionSource(selectedSource);
    }

    const checkHandler = () => {
        setAgGrid(!AgGrid)
      }

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    //ACENDING or DECENDING
    if (sort_ascending) {
        sortedAlbums = albums.sort((a, b) => {
            return a.rank - b.rank;
        });
    }
    else {
        sortedAlbums = albums;
    }

    sortedReferences = references.sort((a, b) => {
        return b.ref_number - a.ref_number;
    });

    //RangeSelection if large lists
    if (range_200 || range_500) {

        if (optionRange === '1-100' && unranked !== true) {
            rangeArray = sortedAlbums.filter(e => e.rank < 101);  
            } 
        if (optionRange === '1-100' && unranked === true) {
            rangeArray = sortedAlbums;
            } 
        if (optionRange === '101-200') {
            rangeArray = sortedAlbums.filter(e => e.rank > 100 && e.rank < 201);  
            } 
        if (optionRange === '201-300') {
            rangeArray = sortedAlbums.filter(e => e.rank > 200 && e.rank < 301);  
            } 
        if (optionRange === '301-400') {
            rangeArray = sortedAlbums.filter(e => e.rank > 300 && e.rank < 401);  
            } 
        if (optionRange === '401-500') {
            rangeArray = sortedAlbums.filter(e => e.rank > 400 && e.rank < 501);  
            } 
        if (optionRange === 'All') {
            rangeArray = sortedAlbums;  
            }
    }

    //RangeSelection decade
    if (decade) {
        rangeArray = sortedAlbums.filter(e => e.released_year === decadeRange);  
        } 

    //Search
    if (searchString) {
        filterArray = SearchLists(sortedAlbums, 'searchtitle', searchString.toLowerCase());
        }  

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

    //FILTERS
    if (optionSource) {
        filterArray = sortedAlbums.filter(e => e.source === optionSource.value);
        } 
    if (optionLabel) {
        filterArray = sortedAlbums.filter(e => e.label === optionLabel.value);
        } 
    if (optionYear) {
        filterArray = sortedAlbums.filter(e => e.year === optionYear.value);
        } 
    if (optionArtist && !honors_awards) {
        filterArray = sortedAlbums.filter(e => e.artist === optionArtist.value);
        } 
    // Her må eg finne på noe lurt
    if (optionArtist && honors_awards) {
        filterArray = sortedAlbums.filter(e => e.artist === optionArtist.value);
        } 
    if (optionSimpleArtist) {
        filterArray = sortedAlbums.filter(e => e.simple_artist === optionSimpleArtist.value);
        } 
    if (!rangeArray && !searchString && !optionArtist && !optionLabel && !optionYear && !optionSource && !optionSimpleArtist) {
        filterArray = sortedAlbums;
    }
    //Range
    if (rangeArray && !optionYear && !optionArtist && !optionLabel && !optionSource && !searchString && !optionSimpleArtist) {
        filterArray = rangeArray
        } 

    //HEADER
    const listHeading = list.map(value => {

        ListPic = value.picture_link
        ListPic2 = value.picture2
        

        return (
            <React.Fragment key={value.id}>
                <div>
                    <h1>{value.name}</h1>

                    <div className="row">
                        {/* kolonne 1 */}
                        <div className="col-sm-3 mb-3">
                            
                            <img src={ListPic}
                            alt="alt" className="img-fluid" /> 

                            {
                                (() => {
                                if (ListPic2) {
                                return  <div className='mt-2'><img src={ListPic2} alt="alt" className="img-fluid" /> </div>
                                }
                                return 
                                })()
                            }

                            {value.facebook_left &&
                                    <div className="mt-3">
                                        Please check out our <b>Facebook page</b> for daily updates:
                                        -> <b><a href='https://www.facebook.com/JohannasVisions'>Facebook</a></b>
                                    </div>
                                }

                            
                            {(value.in_progress || (window.location.hostname === "localhost")) &&
                            <div>
                                
                                <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                
                                
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs">total count: {albums.length}</div>
                                </div> 
                            </div>
                            }
                            
                        </div>
                        
                        {/* kolonne 2 */}
                        <div className="col-sm-5">

                            {
                                (() => {
                                    if (value.sub_title) {
                                    return  <div className="SubTitle px-3 py-1 mb-2">{value.sub_title}</div>
                                    }
                                    return 
                                })()
                            }
                            
                            {value.description &&
                            <div className="factTextNoWrap rounded px-2 pt-2 pb-1 mb-2">
                                <ReactMarkdown>
                                    {value.description}                     
                                </ReactMarkdown>
                            </div>
                            }

                            {
                                (() => {
                                    if (value.comment1) {
                                    return  <div className="card bg-light px-2 pt-2 mb-3">
                                                <div className="quote2 mb-3">{value.comment1}</div> 
                                                <div className="blockquote-footer"><i>{value.source1}</i></div>
                                            </div>
                                    }
                                    return 
                                })()
                            }

                            
                            {
                                (() => {
                                if (value.spotify) {
                                return  <div className="mb-2"><b>--> <a href={ value.spotify }>
                                Spotify playlist</a></b></div>
                                }
                                return 
                                })()
                            }

                            <div>

                                {
                                    (() => {
                                    if (value.url) {
                                    return  <div>
                                            <b>List source</b>
                                            <div className="Description">
                                                <ul>
                                                    <li>
                                                        <b><a href={value.url}>
                                                            {value.name} ({value.source})
                                                        </a></b>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                    </div>
                                    }
                                    return 
                                    })()
                                }

                                <b>Check out</b>
                                <div className="Description">
                                    <ul>
                                        {
                                            (() => {
                                            if (value.link1 && value.link2 && value.link3 && value.link4) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url4}>
                                                            {value.link4}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2 && value.link3) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2) {
                                            return  <div><li><b><a href={value.url1}>
                                                        {value.link1}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url2}>
                                                        {value.link2}
                                                    </a></b>
                                                    </li>
                                                    </div>
                                            }
                                            return 
                                            })()
                                        }


                                        <li><b><a href='/lists'>
                                            More Lists @ MusicThisDay.com
                                            </a></b>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>

                            {!value.facebook_left &&
                            <div>
                                Please check out our <b>Facebook page</b> for daily updates:
                                -> <b><a href='https://www.facebook.com/JohannasVisions'>Facebook</a></b>
                            </div>
                            }
                        
                        </div>

                        {/* kolonne 3 */}
                        <div className="col-sm-4 mb-1">
                            {(!searchString && !value.no_filters)  &&
                            <div className="row">   
                                <div className="col">
                                    <h4>Filters</h4>
                                </div>
                            </div>
                            }
                            {(!searchString && !optionArtist && !optionLabel && !optionSource && !value.no_year_released)  &&
                            <div className="row pb-2">  
                                <div className="col-9">
                                        <Select
                                            className="select"
                                            value={optionYear}
                                            onChange={selectedOptionYear}
                                            options={YearOptions}
                                            placeholder='select year..'
                                            styles={customStyles2}
                                        />
                                </div>
                                <div className="col-3 pl-2">
                                    <button  className="btn btn-primary btn-sm"  onClick={() => setOptionYear(null)} >Reset</button>
                                </div>
                            </div>
                            }

                            {(!searchString && !optionYear && !optionLabel && !optionSource && !value.single_artist && !simple_artist_select)  &&
                            <div className="row pb-2">  
                                <div className="col-9">
                                    <Select
                                        className="select"
                                        value={optionArtist}
                                        onChange={selectedOptionArtist}
                                        options={ArtistOptions}
                                        placeholder='Select artist..'
                                        styles={customStyles2}
                                    />
                                </div>
                                <div className="col-3 pl-2">
                                    <button  className="btn btn-primary btn-sm"  onClick={() => setOptionArtist(null)} >Reset</button>
                                </div>
                            </div>
                            }

                            {(!searchString && !optionYear && !optionLabel && !optionSource && !value.single_artist && simple_artist_select)  &&
                            <div className="row pb-2">  
                                <div className="col-9">
                                    <Select
                                        className="select"
                                        value={optionSimpleArtist}
                                        onChange={selectedOptionSimpleArtist}
                                        options={SimpleArtistOptions}
                                        placeholder='Select artist..'
                                        styles={customStyles2}
                                    />
                                </div>
                                <div className="col-3 pl-2">
                                    <button  className="btn btn-primary btn-sm"  onClick={() => setOptionSimpleArtist(null)} >Reset</button>
                                </div>
                            </div>
                            }

                            {(!searchString && !optionArtist && !optionYear && !optionSource && value.label_filter)  &&
                            <div className="row pb-2">  
                                <div className="col-9">
                                    <Select
                                        className="select"
                                        value={optionLabel}
                                        onChange={selectedOptionLabel}
                                        options={LabelOptions}
                                        placeholder='Select label..'
                                        styles={customStyles2}
                                    />
                                </div>
                                <div className="col-3 pl-2">
                                    <button  className="btn btn-primary btn-sm"  onClick={() => setOptionLabel(null)} >Reset</button>
                                </div>
                            </div>
                            }  

                            {(!searchString && !optionArtist && !optionLabel && !optionYear &&  value.source_filter)  &&
                            <div className="row pb-2">  
                                <div className="col-9">
                                    <Select
                                        className="select"
                                        value={optionSource}
                                        onChange={selectedOptionSource}
                                        options={SourceOptions}
                                        placeholder='Select source..'
                                        styles={customStyles2}
                                    />
                                </div>
                                <div className="col-3 pl-2">
                                    <button  className="btn btn-primary btn-sm"  onClick={() => setOptionSource(null)} >Reset</button>
                                </div>
                            </div>
                            }      
                            
                            {!optionArtist &&
                            <div className="row">   
                                    <div className="col">
                                    <h4>Search</h4>
                                </div>
                            </div>
                            }

                            {!optionArtist &&
                                <div className="row pb-2">  
                                        <div className="col-9">
                                            <div className="search">    
                                                    <DebounceInput
                                                        //type="number"
                                                        minLength={3}
                                                        debounceTimeout={1000}
                                                        value={searchString}
                                                        onChange={handleSearchChange}
                                                        placeholder="Search all album titles" 
                                                    />
                                                </div>
                                            </div>
                                        <div className="col-3 pl-0">
                                            <button type="button" className="btn btn-primary"  
                                            onClick={() => clearSearchField()} >Reset</button>
                                        </div>
                                </div>
                            }

                            {!hide_grid_option &&
                            <div className="row align-items-start no-gutters">     
                                <div className="col pt-2">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox"
                                            checked={AgGrid}
                                            onChange={checkHandler}
                                            className="form-check-input"
                                        />
                                        Change to Grid
                                        </label>
                                    </div>
                                </div>
                            </div>
                            }


                            {searchString &&
                                <div className='mt-3'>
                                    <div className="btn btn-success btn-sm m-1 p-2 Text14bold">total hits: {filterArray.length}</div>
                                </div> 
                            }

                            {(range_200||range_500) &&
                                <div className='mt-3'>
                                    <div className="btn btn-success btn-sm my-1 mx-2 p-2 Text14bold">Rank {optionRange} selected</div>
                                </div> 
                            }

                            {decadeRange &&
                                <div className='mt-3'>
                                    <div className="btn btn-success btn-sm my-1 mx-2 p-2 Text14bold">Year {decadeRange} selected</div>
                                </div> 
                            }
                
                    
                    </div>
                    
                </div>
                
                {range_500 && !optionArtist && !optionYear &&
                    <div className="row">
                    
                        <div className='col-sm-10 mb-2' align="Left">
                            <div className='mb-2'>Select range to filter:</div>
                            {optionRange === '1-100' && 
                            <button className="btn btn-info btn-xs active focus" onClick={() => setOptionRange('1-100')}>1-100</button>
                            }
                            {optionRange !== '1-100' && 
                            <button type="button" className="btn btn-info btn-sm" onClick={() => setOptionRange('1-100')}>1-100</button>
                            }
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm" onClick={() => setOptionRange('101-200')}>101-200</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm" onClick={() => setOptionRange('201-300')}>201-300</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm" onClick={() => setOptionRange('301-400')}>301-400</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm" onClick={() => setOptionRange('401-500')}>401-500</button>
                            &nbsp;&nbsp;
                        </div>
                     

                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                }

                {range_200 && !optionArtist && !optionYear &&
                    <div className="row">
                    
                        <div className='col-sm-10 mb-2' align="Left">
                            <div className='mb-2'>Select range to filter:</div>
                            {optionRange === '1-100' && 
                            <button className="btn btn-info btn-xs active focus" onClick={() => setOptionRange('1-100')}>1-100</button>
                            }
                            {optionRange !== '1-100' && 
                            <button type="button" className="btn btn-info btn-sm" onClick={() => setOptionRange('1-100')}>1-100</button>
                            }
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm" onClick={() => setOptionRange('101-200')}>101-200</button>
                        </div>
                     

                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                }

                {decade === 1970 && !optionArtist && !optionYear && !searchString && 
                    <div className="row">

                        <div className='col-sm-10 mb-2' align="Left">
                            <div className="ms-1 p-2"><b>Click to use year filter:</b></div>
                            {decadeRange === '1970' && 
                                <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold"  onClick={() => setDecadeRange('1970')}>1970</button>
                            }
                            {decadeRange !== '1970' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1970')}>1970</button>
                            }
                            {decadeRange === '1971' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1971')}>1971</button>
                            }
                            {decadeRange !== '1971' &&
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1971')}>1971</button>
                            }
                            {decadeRange === '1972' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1972')}>1972</button>
                            }
                            {decadeRange !== '1972' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1972')}>1972</button>
                            }
                            {decadeRange === '1973' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1973')}>1973</button>
                            }
                            {decadeRange !== '1973' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1973')}>1973</button>
                            }
                            {decadeRange === '1974' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1974')}>1974</button>
                            }
                            {decadeRange !== '1974' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1974')}>1974</button>
                            }
                            {decadeRange === '1975' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1975')}>1975</button>
                            }
                            {decadeRange !== '1975' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1975')}>1975</button>
                            }
                            {decadeRange === '1976' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1976')}>1976</button>
                            }
                            {decadeRange !== '1976' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1976')}>1976</button>
                            }
                            {decadeRange === '1977' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1977')}>1977</button>
                            }
                            {decadeRange !== '1977' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1977')}>1977</button>
                            }
                            {decadeRange === '1978' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1978')}>1978</button>
                            }
                            {decadeRange !== '1978' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1978')}>1978</button>
                            }
                            {decadeRange === '1979' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1979')}>1979</button>
                            }
                            {decadeRange !== '1979' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1979')}>1979</button>
                            }
                        </div>

                        <div className="col-sm-2 mt-5" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                }

                {decade === 1960 && !optionArtist && !optionYear && !searchString && 
                    <div className="row">

                        <div className='col-sm-10 mb-2' align="Left">
                            <div className="ms-1 p-2"><b>Click to use year filter:</b></div>
                            {decadeRange === '1960' && 
                                <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold"  onClick={() => setDecadeRange('1960')}>1960</button>
                            }
                            {decadeRange !== '1960' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1960')}>1960</button>
                            }
                            {decadeRange === '1961' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1961')}>1961</button>
                            }
                            {decadeRange !== '1961' &&
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1961')}>1961</button>
                            }
                            {decadeRange === '1962' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1962')}>1962</button>
                            }
                            {decadeRange !== '1962' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1962')}>1962</button>
                            }
                            {decadeRange === '1963' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1963')}>1963</button>
                            }
                            {decadeRange !== '1963' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1963')}>1963</button>
                            }
                            {decadeRange === '1964' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1964')}>1964</button>
                            }
                            {decadeRange !== '1964' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1964')}>1964</button>
                            }
                            {decadeRange === '1965' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1965')}>1965</button>
                            }
                            {decadeRange !== '1965' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1965')}>1965</button>
                            }
                            {decadeRange === '1966' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1966')}>1966</button>
                            }
                            {decadeRange !== '1966' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1966')}>1966</button>
                            }
                            {decadeRange === '1967' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1967')}>1967</button>
                            }
                            {decadeRange !== '1967' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1967')}>1967</button>
                            }
                            {decadeRange === '1968' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1968')}>1968</button>
                            }
                            {decadeRange !== '1968' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1968')}>1968</button>
                            }
                            {decadeRange === '1969' && 
                            <button type="button" className="btn btn-success btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1969')}>1969</button>
                            }
                            {decadeRange !== '1969' && 
                            <button type="button" className="btn btn-primary btn-sm m-1 p-2 Text14bold" onClick={() => setDecadeRange('1969')}>1969</button>
                            }
                        </div>

                        <div className="col-sm-2 mt-5" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                }

                {!value.range_500 && !value.range_200 && !decade &&
                    <div className="row">
                        <div className="col pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                }
                    
                </div>
            </React.Fragment>
            )
        }
    )

    //ALBUMS
    const albumItems = filterArray.map(value => {

        let LineUpCharLimit, DescCharLimit, TracksCharLimit, line_up, row_group_start_class, 
        album_row_class

        if (value.charlimit_tracks) {
            TracksCharLimit = value.charlimit_tracks;
        }

        if (value.tracks_album_lists && !value.charlimit_tracks) {
            TracksCharLimit = value.tracks_album_lists;
        }

        if (!value.tracks_album_lists && !value.charlimit_tracks) {
            TracksCharLimit = 400;
        } 

        if (value.charlimit_lineup) {
            LineUpCharLimit = value.charlimit_lineup;
        }

        if (value.line_up_album_lists && !value.charlimit_lineup) {
            LineUpCharLimit = value.line_up_album_lists;
        }
        if (!value.line_up_album_lists && !value.charlimit_lineup) {
            LineUpCharLimit = 700
        }

        if (value.charlimit_description) {
            DescCharLimit = value.charlimit_description;
        }

        if (value.desc_album_lists && !value.charlimit_description) {
            DescCharLimit = value.desc_album_lists;
        }
        if (!value.desc_album_lists && value.album_slim && !value.charlimit_description) {
            DescCharLimit = 750
        }
        if (!value.desc_album_lists && !value.album_slim && !value.charlimit_description) {
            DescCharLimit = 1000
        }

        if (value.personnel) {
            line_up = true;
        }

        row_group_start_class = 'row sortTitle p-2 border-start border-end border-top border-4';

        if (value.group_name && value.group_start && !optionArtist) {
            album_row_class = 'row p-3 border-start border-end border-top border-4';
        }
        if (value.group_name && value.group_start && optionArtist) {
            album_row_class = 'row mb-4 p-3 border border-4';
        }

        if (value.group_name && !value.group_end && !value.group_start && !optionArtist) {
            album_row_class = 'row AlbumSuperslim p-2 border-start border-end border-bottom border-4';
        }
        if (value.group_name && !value.group_end && !value.group_start && optionArtist) {
            album_row_class = 'row AlbumSuperslim mb-4 p-2 border-start border-end border-bottom border-4';
        }

        if (value.group_name && value.group_end) {
            album_row_class = 'row AlbumSuperslim mb-5 p-2 border-start border-end border-bottom border-4';
        }
        if (!value.group_name){
            album_row_class = 'row p-3 mb-4 border rounded border-2';
        }

        /* row AlbumSuperslim border-start border-end border-3 */

        

        return (
            <React.Fragment key={value.id}>

                {value.group_name && value.group_start &&
                    <div className={row_group_start_class}>
                        <div className='col'>
                                {value.group_name}
                        </div>
                    </div>
                }

                {value.group_name && optionArtist && !value.group_start &&
                    <div className={row_group_start_class}>
                        <div className='col'>
                                {value.group_name}
                        </div>
                    </div>
                }

                {value.group_name && searchString && !value.group_start &&
                    <div className={row_group_start_class}>
                        <div className='col'>
                                {value.group_name}
                        </div>
                    </div>
                }

                {/* KUN en enkel rad */}
                {value.album_super_slim && !value.group_name && !album_covers &&
                    <div className="col-sm-12 my-3 AlbumSuperslim border rounded border-2">
                        <div>&nbsp;<b>{value.rank} - {value.title}</b> by {value.artist} ({value.album_released}) &nbsp;&nbsp;&nbsp;     
                        </div>
                    </div>
                }

                {/* KUN en enkel rad i Group*/}
                {value.group_name && honors_awards && !value.group_start && !album_covers &&
                    <div className={album_row_class}>
                        <div className='col'>
                            {value.picture &&
                            <div className="float-md-end ms-md-2">
                                <img border="0" alt="album" src={value.picture} width="75" height="75" />
                            </div>  
                            }
                            <div className="bestSongsTitle"><b>{value.title}</b></div>
                            {value.artist &&
                                <div>{value.artist} ({value.label})</div> 
                            }
                            {/* {value.artist_text &&
                                <div>{value.artist_text} ({value.label})</div> 
                            } */}
                            
                        </div>
                    </div>
                }

                {/* Simple visning */}
                {value.album_slim && !album_covers && !(value.group_name && honors_awards && !value.group_start) &&
                    <div className="col-sm-12 mt-3 mb-1">
                        <div className="row py-3 mb-4 border rounded border-2">
                            {/* 1 kolonne */}
                                <div className="col-sm-3">
                                    <div align="center">
                                        {value.rank &&
                                            <div className='AlbumTitle bg-success rounded mb-2 p-1'><b>{value.rank} - {value.title}</b></div> 
                                        }
                                        {!value.rank &&
                                            <div className="AlbumTitle bg-success rounded mb-2 p-1"><b>{value.title}</b></div>
                                        }
                                        
                                    </div> 

                                    <div align="center">
                                        <img src={value.picture} alt=""
                                            className="img-fluid"></img>
                                    </div>

                                    {value.artist_text &&
                                    <div align="center">
                                        <div className="BestSong rounded my-2 p-1">by <b>{value.artist_text}</b></div>
                                    </div> 
                                    }
                                    
                                    {value.artist && !value.artist_text &&
                                    <div align="center">
                                        <div className="BestSong rounded my-2 p-1">by <b>{value.artist}</b></div>
                                    </div> 
                                    }

                                    {value.source &&
                                        <div className="mt-1"><b>Selected by</b> <br />
                                            <div className="factText_green p-1 rounded">{value.source}</div>
                                        </div>
                                    }

                                    {/* <div className="row">
                                        <div className='col'>
                                            <div className='my-2 ml-3'>
                                                <b>Selected by</b>
                                                    <div className="factText_green p-1 font-weight-bold">{value.source}</div>
                                            </div>
                                        </div>
                                    </div> */}
        
                                </div>
                            {/* 2 kolonne */}
                                <div className="col-sm-3">
                                    {value.album_released &&
                                        <div className="mt-1"><b>Released</b> <br />
                                            <div className="Date p-1 rounded">{value.album_released}</div>
                                        </div>
                                    }
                                    {value.ar_text && !value.album_released &&
                                        <div className="mt-1"><b>Released</b> <br />
                                            <div className="factText15 p-1 rounded">{value.ar_text}</div>
                                        </div>
                                    }


                                    {value.rd_text && !value.studio_txt &&
                                        <div className="mt-1"><b>Recorded</b> <br />
                                            <div className="factText15 p-1 rounded">{value.rd_text}</div>
                                        </div>
                                    }
                                    {value.rd_text && value.studio_txt &&
                                        <div className="mt-1"><b>Recorded</b> <br />
                                            <div className="factText15 p-1 rounded">{value.rd_text} @ {value.studio_txt}</div>
                                        </div>
                                    }
                                    {value.recorded && value.studio_txt &&
                                        <div className="mt-1"><b>Recorded</b> <br />
                                            <div className="Date p-1 rounded">{value.recorded}</div>
                                            <div className="factText15 p-1 rounded">{value.studio_txt}</div>
                                        </div>
                                    }
                                    
                                    {value.label &&
                                        <div className="mt-1"><b>Label</b> <br />
                                            <div className="factText15 p-1 rounded">{value.label}</div>
                                        </div>
                                    }
                                    {value.producer &&
                                        <div className="mt-1"><b>Producer</b> <br />
                                            <div className="factText15 p-1 rounded">{value.producer}</div>
                                        </div>
                                    }
                                    {value.genre &&
                                        <div className="mt-1 mb-2"><b>Genre</b> <br />
                                            <div className="factText15 p-1 rounded">{value.genre}</div>
                                        </div>
                                    }
                                    {value.length &&
                                        <div className="mt-1 mb-2"><b>Length</b> <br />
                                            <div className="factText15 p-1 rounded">{value.length}</div>
                                        </div>
                                    }

                                    {value.wiki && value.spotify &&
                                        <div className="row mt-2">
                                            <div className="col">
                                                 <em> -> <a href={value.wiki}>more info..</a></em>

                                                 <div className="float-md-end ms-md-">
                                                    <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                                    <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=30,height=30" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {value.wiki && !value.spotify &&
                                        <div className="Description mt-3"> 
                                        <em> -> <a href={value.wiki}>more info..</a></em>
                                        </div>
                                    }
                                    {value.spotify && !value.wiki &&
                                    <div className="float-md-end ms-md-">
                                        <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                        <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=30,height=30" /></a>
                                    </div>
                                    }
                                    
                                    
                                </div>
                            {/* 3 kolonne */}
                                <div className="col-sm-3">

                                    {value.track_listing &&
                                    <div className="mt-1"><b>Tracklist</b> <br />
                                        <div className="factText p-2 rounded">
                                            <ReadMoreAndLess
                                                    charLimit={400}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.track_listing}
                                            </ReadMoreAndLess> 
                                        </div>
                                    </div>
                                    }

                                    {!value.track_listing && value.description &&
                                        <div>
                                            <div className="Description"> 
                                                <ReadMoreAndLess
                                                        charLimit={DescCharLimit}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {value.description}
                                                </ReadMoreAndLess>   
                                            </div>
                                        
                                        </div>
                                    }

                                    {value.personnel &&
                                        <div>
                                            <div className="mt-2">
                                                <b>Line-up</b> <br />
                                                <div className="factTextMarkdown p-1 rounded"> 
                                                    <ReactMarkdown>
                                                        {value.personnel}                    
                                                    </ReactMarkdown> 
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {value.comment &&
                                    <div className="card bg-light p-2 mt-2">
                                        <div className="quote3 mb-3">{value.comment}</div> 
                                            {
                                                (() => {
                                                    if (value.source_txt_details) {
                                                        return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                    }
                                                    return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                })()

                                            }
                                    </div>
                                    }

                                    {value.main_comment && !value.comment &&
                                        <div className="card bg-light px-2 pt-2 mt-1">
                                            <div className="quote3 mb-3">{value.main_comment}</div> 
                                            <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                                        </div>
                                    }       
                                    

                                </div>
                            {/* 4 kolonne */}
                                <div className="col-sm-3">
                                    {value.youtube &&
                                        <div>
                                            <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                            <div className="border d-flex justify-content-center flex-nowrap">  
                                                <ReactPlayer
                                                    url={ value.youtube }
                                                    controls
                                                    light='true'
                                                    volume={0.9}
                                                    width="380px"
                                                    height="300px"
                                                    /> 
                                            </div>
                                        </div>
                                    }

                                </div>
                        </div>
                    </div>
                }
                 {/* Album Covers visning */}
                 {album_covers &&
                 <div className="col-sm-12 mt-3 mb-1">
                    <div className="row py-3 mb-4 border rounded border-2">
                        {/* 1 kolonne */}
                            <div className="col-sm-5">
                                <div align="center">
                                    {value.rank &&
                                        <div className='AlbumTitle bg-success rounded mb-2 p-1'><b>{value.rank} - {value.title}</b></div> 
                                    }
                                    {!value.rank &&
                                        <div className="AlbumTitle bg-success rounded mb-2 p-1"><b>{value.title}</b></div>
                                    }
                                </div> 

                                {value.discogs_cover &&
                                <div align="center">
                                    <img src={value.discogs_cover} alt=""
                                        className="img-fluid"></img>
                                </div>
                                }
                                
                                {!value.discogs_cover &&
                                <div align="center">
                                    <img src={value.picture} alt=""
                                        className="img-fluid"></img>
                                </div>
                                }
                                

                                {value.artist_text &&
                                <div align="center">
                                    <div className="BestSong rounded my-2 p-1">by <b>{value.artist_text}</b></div>
                                </div> 
                                }
                                
                                {value.artist && !value.artist_text &&
                                <div align="center">
                                    <div className="BestSong rounded my-2 p-1">by <b>{value.artist}</b></div>
                                </div> 
                                }

                                {value.source &&
                                    <div className="mt-1"><b>Selected by</b> <br />
                                        <div className="factText_green p-1 rounded">{value.source}</div>
                                    </div>
                                }
    
                            </div>
                        {/* 2 kolonne */}
                        <div className="col-sm-5">
                            {value.cover_description &&
                                <div>
                                    <div className="DescriptionBig16 p-3 rounded"> 
                                        <ReactMarkdown>
                                            {value.cover_description}                                        
                                        </ReactMarkdown> 
                                    </div>
                                
                                </div>
                            }
                            {value.main_comment &&
                                <div className="card bg-light p-2 mt-2">
                                    <div className="quote4 mb-3">{value.main_comment}</div> 
                                     <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                                </div>
                            }
                        </div>

                        {/* 3 kolonne */}
                            <div className="col-sm-2">
                                {value.album_released &&
                                    <div className="mt-1"><b>Released</b> <br />
                                        <div className="Date p-1 rounded">{value.album_released}</div>
                                    </div>
                                }
                                {value.ar_text && !value.album_released &&
                                    <div className="mt-1"><b>Released</b> <br />
                                        <div className="factText15 p-1 rounded">{value.ar_text}</div>
                                    </div>
                                }


                                {value.rd_text && !value.studio_txt &&
                                    <div className="mt-1"><b>Recorded</b> <br />
                                        <div className="factText15 p-1 rounded">{value.rd_text}</div>
                                    </div>
                                }
                                {value.rd_text && value.studio_txt &&
                                    <div className="mt-1"><b>Recorded</b> <br />
                                        <div className="factText15 p-1 rounded">{value.rd_text} @ {value.studio_txt}</div>
                                    </div>
                                }
                                
                                {value.label &&
                                    <div className="mt-1"><b>Label</b> <br />
                                        <div className="factText15 p-1 rounded">{value.label}</div>
                                    </div>
                                }
                                {value.producer &&
                                    <div className="mt-1"><b>Producer</b> <br />
                                        <div className="factText15 p-1 rounded">{value.producer}</div>
                                    </div>
                                }
                                {value.genre &&
                                    <div className="mt-1 mb-2"><b>Genre</b> <br />
                                        <div className="factText15 p-1 rounded">{value.genre}</div>
                                    </div>
                                }
                                {value.length &&
                                    <div className="mt-1 mb-2"><b>Length</b> <br />
                                        <div className="factText15 p-1 rounded">{value.length}</div>
                                    </div>
                                }

                                {value.wiki && value.spotify &&
                                    <div className="row mt-2">
                                        <div className="col">
                                            <em> -> <a href={value.wiki}>more info..</a></em>

                                            <div className="float-md-end ms-md-">
                                                <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                                <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=30,height=30" /></a>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {value.wiki && !value.spotify &&
                                    <div className="Description mt-3"> 
                                    <em> -> <a href={value.wiki}>more info..</a></em>
                                    </div>
                                }
                                {value.spotify && !value.wiki &&
                                <div className="float-md-end ms-md-">
                                    <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                    <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=30,height=30" /></a>
                                </div>
                                }
                                
                                
                            </div>
                        
                    </div>
                </div>

                 }

                {!value.album_super_slim && !value.album_slim && !album_covers && !(value.group_name && honors_awards && !value.group_start) &&
                    <div> 

                        {/* Forskjellig layout avhengig om description finnes */}
                        {
                            (() => {
                            if (value.description) {
                            return <div>
                                        <div className={album_row_class}>
                                            {/* 1 kolonne */}
                                            <div className="col-sm-3">

                                                {value.group_name && value.group_start &&
                                                    <div className='AlbumTitle text-center bg-info rounded mb-2 p-1'
                                                    ><b>ALBUM OF THE YEAR</b></div> 
                                                }

                                                {
                                                    (() => {
                                                    if (value.rank) {
                                                    return  <div className='AlbumTitle text-center bg-success rounded mb-2 p-1'><b>{value.rank} - {value.title}</b></div>  
                                                    }
                                                    return <div className='AlbumTitle text-center bg-success rounded mb-2 p-1'><b>{value.title}</b></div> 
                                                    })()
                                                }

                                                <div align="center">
                                                <img src={value.picture} alt="" className="img-fluid"></img>
                                                </div>

                                                {value.artist_text &&
                                                    <div align="center">
                                                        <div className="BestSong text-center rounded my-2 p-1">by <b>{value.artist_text}</b></div>
                                                    </div> 
                                                }

                                                {value.aka && !value.artist_text &&
                                                    <div align="center">
                                                        <div className="BestSong text-center rounded my-2 p-1">by <b>{value.artist}</b></div>
                                                    </div> 
                                                }       

                                    
                                                {value.artist && !value.artist_text && !value.aka &&
                                                    <div align="center">
                                                        <div className="BestSong text-center rounded my-2 p-1">by <b>{value.artist}</b></div>
                                                    </div> 
                                                }

                                                
                                                {
                                                    (() => {
                                                    if (value.spotify && value.youtube) {
                                                        return <div>
                                                                    <div className="row">
                                                                        <div className="col-6 pr-3" align="Right">
                                                                            <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                                                                <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=40,height=40" /></a>
                                                                                &nbsp;&nbsp;
                                                                                <br />
                                                                                <b>spotify</b>
                                                                        </div>
                                                                        <div className="col-6" align="Left">
                                                                            <div>&nbsp;&nbsp;&nbsp;<a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                                                                <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=40,height=40" /></a>
                                                                                <br />
                                                                                <b>YouTube</b>
                                                                                </div>  
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                    if (value.spotify && !value.youtube) {
                                                        return <div>
                                                                <div className="row">
                                                                    <div className="col" align="center">
                                                                        <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                                                                <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=40,height=40" /></a>
                                                                                &nbsp;&nbsp;
                                                                                <br />
                                                                                <b>spotify</b>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    } if (value.youtube && !value.spotify) {
                                                        return <div>
                                                                <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                                    <div align="center">&nbsp;&nbsp;&nbsp;<a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                                                        <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=40,height=40" /></a>
                                                                        <br />
                                                                        <b>YouTube</b>
                                                                    </div>  
                                                        </div>
                                                    } return 
                                                    
                                                    })()
                                                }
                                                 

                                                <div className="row">
                                                    <div className='col'>
                                                        {   
                                                            (() => {
                                                            if (value.producer) {
                                                            return <div className='mt-2 ml-3 p-1 rounded'>
                                                                    <b>Producer(s)</b> <br />
                                                                        <div className="factText p-1 rounded">{value.producer}</div>
                                                                    </div>
                                                            } 
                                                            return 
                                                            })()
                                                        }
                                                    </div>
                                                </div>
                                                
                                                        {   
                                                            (() => {
                                                            if (value.source) {
                                                            return <div className="row">
                                                                    <div className='col'>
                                                                        <div className='my-2 ml-3'>
                                                                            <b>Selected by</b>
                                                                                <div className="factText_green p-1 font-weight-bold rounded">{value.source}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            } 
                                                            return 
                                                            })()
                                                        }
                                                    

                                                {
                                                    (() => {
                                                    if (value.track_listing && (!value.tracks_center) && !(album_tracks_center===true)) {
                                                        return <div className="row pt-2">
                                                                    <div className="col-12">
                                                                        <b>Track listing</b><br />
                                                                        <div className="bg-light">
                                                                            <div className="py-1 px-1 Description">
                                                                                <ReadMoreAndLess
                                                                                        charLimit={LineUpCharLimit}
                                                                                        readMoreText={" Read more ▼"}
                                                                                        readLessText={" Read less ▲"}
                                                                                        readMoreClassName="read-more-less--more"
                                                                                        readLessClassName="read-more-less--less"
                                                                                    >
                                                                                    {value.track_listing}
                                                                                </ReadMoreAndLess> 
                                                                        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                    return 
                                                    })()
                                                }

                                            </div>

                                            {/* 2 kolonne */}
                                            <div className="col-sm-5 pb-3 border-right">

                                                <div className="Description"> 
                                                        <ReadMoreAndLess
                                                                charLimit={DescCharLimit}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.description}
                                                        </ReadMoreAndLess> 

                                                            <br />
                                                            <em> -> <a href={value.wiki}>more info..</a></em>
                                                </div>

                                                <div className="pt-3">
                                                    {
                                                        (() => {
                                                            if (value.comment) {
                                                            return <div className="card bg-light py-2 px-2">
                                                                        <div className="quote3 mb-3">{value.comment}</div> 
                                                                        
                                                                        {
                                                                            (() => {
                                                                                if (value.source_txt_details) {
                                                                                    return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                                                }
                                                                                return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                                            })()

                                                                        }

                                                                    </div>
                                                            }
                                                            return 
                                                        })()
                                                    }

                                                    {
                                                        (() => {
                                                            if (value.comment_more && comment3) {
                                                            return <div className="card bg-light mt-2 py-2 px-2">
                                                                        <div className="quote3 mb-3">{value.comment_more}</div> 
                                                                        <div className="blockquote-footer"><i>{value.source_more}</i></div>
                                                                    </div>
                                                            }
                                                            return 
                                                        })()
                                                    }

                                                    
                                                    {
                                                    (() => {
                                                    if (value.track_listing && value.tracks_center) {
                                                        return <div className="row pt-3">
                                                                    <div className="col-12">
                                                                        <b>Track listing</b><br />
                                                                        <div className="bg-light">
                                                                            <div className="py-1 px-1 Description">
                                                                                <ReadMoreAndLess
                                                                                        charLimit={TracksCharLimit}
                                                                                        readMoreText={" Read more ▼"}
                                                                                        readLessText={" Read less ▲"}
                                                                                        readMoreClassName="read-more-less--more"
                                                                                        readLessClassName="read-more-less--less"
                                                                                    >
                                                                                    {value.track_listing}
                                                                                </ReadMoreAndLess>
                                                                        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                    return 
                                                        })()
                                                    }

                                                </div>

                                                {/* video center */}
                                                {
                                                    (() => {
                                                    if (value.video1 && value.video_tracks_center) {
                                                        return <div>
                                                                    <div className="row">
                                                                        <div className='col-12 mt-3'>
                                                                            <div className='videoTitle pl-2 mx-2' align='center'><strong>My selected tracks</strong></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                        <div className='col-sm-6 mb-2'>
                                                                            <div className="card bg-light p-2">
                                                                                <div className="mx-1">  
                                                                                    <ReactPlayer
                                                                                        url={ value.video1 }
                                                                                                light='true'
                                                                                                //light
                                                                                                controls
                                                                                                width="280"
                                                                                                height="200"
                                                                                    />
                                                                                </div>
                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                    <strong>{value.desc1}</strong>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                        <div className='col-sm-6 mb-2'>
                                                                            {
                                                                                (() => {
                                                                                if (value.video2) {
                                                                                    return <div className="card bg-light p-2">
                                                                                                <div className="mx-1">  
                                                                                                    <ReactPlayer
                                                                                                        url={ value.video2 }
                                                                                                                light='true'
                                                                                                                //light
                                                                                                                controls
                                                                                                                width="280"
                                                                                                                height="200"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                                    <strong>{value.desc2}</strong>
                                                                                                </div>
                                                                                            </div>
                                                                                }
                                                                                return 
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    }
                                                    return 
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                    if (value.video3 && value.video_tracks_center) {
                                                        return <div>
                                                                    <div className="row mt-1">
                                                                        <div className='col-sm-6 mb-2'>
                                                                            <div className="card bg-light p-2">
                                                                                <div className="mx-1">  
                                                                                    <ReactPlayer
                                                                                        url={ value.video3 }
                                                                                                light='true'
                                                                                                //light
                                                                                                controls
                                                                                                width="280"
                                                                                                height="200"
                                                                                    />
                                                                                </div>
                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                    <strong>{value.desc3}</strong>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-sm-6 mb-2'>
                                                                            {
                                                                                (() => {
                                                                                if (value.video4) {
                                                                                    return <div className="card bg-light p-2">
                                                                                                <div className="mx-1">  
                                                                                                    <ReactPlayer
                                                                                                        url={ value.video4 }
                                                                                                                light='true'
                                                                                                                //light
                                                                                                                controls
                                                                                                                width="280"
                                                                                                                height="200"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                                    <strong>{value.desc4}</strong>
                                                                                                </div>
                                                                                            </div>
                                                                                }
                                                                                return 
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                    return 
                                                    })()
                                                }

                                            </div>

                                            {/* 3 kolonne */}
                                            <div className="col-sm-4 pb-4">

                                                <div className="row">

                                                    <div className="col pr-0 mr-2">
                                                            <div>
                                                                {
                                                                    (() => {
                                                                        if (value.rd_text) {
                                                                        return <div><b>Recorded </b><br />
                                                                        <div className="DateTxt p-1 rounded">{value.rd_text}</div>
                                                                        
                                                                        </div>
                                                                        }
                                                                        if (value.recorded) {
                                                                            return <div><b>Recorded </b><br />
                                                                                <div className="Date p-1 rounded">{value.recorded}</div>
                                                                                
                                                                                </div>
                                                                        }
                                                                    })()
                                                                }

                                                                {   
                                                                    (() => {
                                                                    if (value.studio_txt) {
                                                                    return <div>
                                                                                <b>Location</b> <br />
                                                                                <div className="factText p-1 rounded">{value.studio_txt}</div>
                                                                                
                                                                            </div>
                                                                    } 
                                                                    return 
                                                                    })()
                                                                }
                                                                {   
                                                                    (() => {
                                                                    if (value.engineer) {
                                                                    return <div>
                                                                                <b>Engineer</b> <br />
                                                                                <div className="factText p-1 rounded">{value.engineer}</div>
                                                                                
                                                                            </div>
                                                                    } 
                                                                    return 
                                                                    })()
                                                                }
                                                                {value.length &&
                                                                <div>
                                                                    <b>Length</b> <br />
                                                                    <div className="factText p-1 rounded">{value.length}</div>
                                                                </div>
                                                                }
                                                            
                                                            </div>

                                                    </div>

                                                    <div className="col pl-1">

                                                            {
                                                                (() => {
                                                                if (value.album_released) {
                                                                return <div><b>Released</b> <br />
                                                                <div className="Date p-1 rounded">
                                                                    {value.album_released}</div></div>
                                                                } else if (value.ar_text) {
                                                                return <div><b>Released</b> <br />
                                                                <div className="Date p-1 rounded">
                                                                    {value.ar_text}</div></div>
                                                                }
                                                                })()
                                                            }
                                                            

                                                            
                                                            <div>
                                                            <b>Label</b> <br />
                                                            <div className="factText p-1 rounded">{value.label}</div>
                                                            </div>
                                                            {   
                                                                (() => {
                                                                if (value.genre) {
                                                                return <div>
                                                                        <b>Genre</b> <br />
                                                                        <div className="factText p-1 rounded">{value.genre}</div>
                                                                    </div>
                                                                } 
                                                                return 
                                                                })()
                                                            }
                                                            
                                    
                                                    </div>

                                                </div>

                                                

                                                <div className="row pt-3">
                                                    <div className="col-12">

                                                        {(markdown_personnel || value.markdown_line_up) && line_up &&
                                                            <div>
                                                                <b>Line-up</b> <br />
                                                                <div className="factTextMarkdown p-1 rounded"> 
                                                                    <ReactMarkdown>
                                                                        {value.personnel}                    
                                                                    </ReactMarkdown> 
                                                                </div>
                                                            </div>
                                                        }

                                                        {(!markdown_personnel && !value.markdown_line_up) && line_up &&
                                                            <div><b>Line-up</b> <br />
                                                                <div className="factText p-1 rounded">  
                                                                <ReadMoreAndLess
                                                                        charLimit={LineUpCharLimit}
                                                                        readMoreText={" Read more ▼"}
                                                                        readLessText={" Read less ▲"}
                                                                        readMoreClassName="read-more-less--more"
                                                                        readLessClassName="read-more-less--less"
                                                                    >
                                                                    {value.personnel}
                                                                </ReadMoreAndLess>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col pt-3 mb-3">
                                                            {
                                                                    (() => {
                                                                    if (value.comment_2) {
                                                                    return <div className="card bg-light p-2">
                                                                                <div className="quote3 mb-3">{value.comment_2}</div> 
                                                                                
                                                                                {
                                                                                    (() => {
                                                                                        if (value.source_txt_details_2) {
                                                                                            return <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                                                                        }
                                                                                        return <div className="blockquote-footer"><i>{value.source_txt_2}</i></div>

                                                                                    })()

                                                                                }

                                                                            </div>
                                                                    }
                                                                    return 
                                                                })()
                                                            }

                                                    </div>

                                                </div>

                                                {value.main_comment && !value.comment_2 &&
                                                    <div className="card bg-light px-2 pt-2 mt-1">
                                                        <div className="quote3 mb-3">{value.main_comment}</div> 
                                                        <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                                                    </div>
                                                }

                                                {
                                                    (() => {
                                                    if (value.video1 && !value.video_tracks_center) {
                                                        return <div>
                                                                    <div className="row">
                                                                        <div className='col-12'>
                                                                            <div className='videoTitle pl-2 mx-2' align='center'><strong>My selected tracks</strong></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                        <div className='col-sm-6 mb-2'>
                                                                            <div className="card bg-light p-2">
                                                                                <div className="mx-1">  
                                                                                    <ReactPlayer
                                                                                        url={ value.video1 }
                                                                                                light='true'
                                                                                                //light
                                                                                                controls
                                                                                                width="280"
                                                                                                height="200"
                                                                                    />
                                                                                </div>
                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                    <strong>{value.desc1}</strong>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                        <div className='col-sm-6 mb-2'>
                                                                            {
                                                                                (() => {
                                                                                if (value.video2) {
                                                                                    return <div className="card bg-light p-2">
                                                                                                <div className="mx-1">  
                                                                                                    <ReactPlayer
                                                                                                        url={ value.video2 }
                                                                                                                light='true'
                                                                                                                //light
                                                                                                                controls
                                                                                                                width="280"
                                                                                                                height="200"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                                    <strong>{value.desc2}</strong>
                                                                                                </div>
                                                                                            </div>
                                                                                }
                                                                                return 
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    }
                                                    return 
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                    if (value.video3 && !value.video_tracks_center) {
                                                        return <div>
                                                                    <div className="row mt-1">
                                                                        <div className='col-sm-6 mb-2'>
                                                                            <div className="card bg-light p-2">
                                                                                <div className="mx-1">  
                                                                                    <ReactPlayer
                                                                                        url={ value.video3 }
                                                                                                light='true'
                                                                                                //light
                                                                                                controls
                                                                                                width="280"
                                                                                                height="200"
                                                                                    />
                                                                                </div>
                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                    <strong>{value.desc3}</strong>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-sm-6 mb-2'>
                                                                            {
                                                                                (() => {
                                                                                if (value.video4) {
                                                                                    return <div className="card bg-light p-2">
                                                                                                <div className="mx-1">  
                                                                                                    <ReactPlayer
                                                                                                        url={ value.video4 }
                                                                                                                light='true'
                                                                                                                //light
                                                                                                                controls
                                                                                                                width="280"
                                                                                                                height="200"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                                    <strong>{value.desc4}</strong>
                                                                                                </div>
                                                                                            </div>
                                                                                }
                                                                                return 
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                    return 
                                                    })()
                                                }

                                            </div>
                                        </div>
                                    </div>
                            }
                            return <div>
                                        <div className={album_row_class}> 
                                            {/* 1 kolonne */}
                                            <div className="col-sm-3">

                                                {
                                                    (() => {
                                                    if (value.rank) {
                                                    return  <p className='Song text-center'><b>{value.rank} - {value.title}</b></p>  
                                                    }
                                                    return <p className='Song text-center'><b>{value.title}</b></p> 
                                                    })()
                                                }

                                                <div align="center">
                                                <img src={value.picture} alt="" height="250" width="250" 
                                                className="rounded mx-auto d-block"></img>
                                                </div>
                                                {
                                                    (() => {
                                                    if (value.aka) {
                                                        return <p className='text-center mt-2'>by <b>{value.aka}</b></p>;
                                                    }
                                                    return <p className='text-center mt-2'>by <b>{value.artist}</b></p>
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                    if (value.spotify && value.youtube) {
                                                        return <div>
                                                                    <div className="row">
                                                                        <div className="col-6 pr-3" align="Right">
                                                                            <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                                                                <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=40,height=40" /></a>
                                                                                &nbsp;&nbsp;
                                                                                <br />
                                                                                <b>spotify</b>
                                                                        </div>
                                                                        <div className="col-6" align="Left">
                                                                            <div>&nbsp;&nbsp;&nbsp;<a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                                                                <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=40,height=40" /></a>
                                                                                <br />
                                                                                <b>YouTube</b>
                                                                            </div>  
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                    if (value.spotify && !value.youtube) {
                                                        return <div>
                                                                <div className="row">
                                                                    <div className="col" align="center">
                                                                        <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                                                                <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=40,height=40" /></a>
                                                                                &nbsp;&nbsp;
                                                                                <br />
                                                                                <b>spotify</b>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    } if (value.youtube && !value.spotify) {
                                                        return <div>
                                                                <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                                        <div align="center">&nbsp;&nbsp;&nbsp;<a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                                                            <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=40,height=40" /></a>
                                                                            <br />
                                                                            <b>YouTube</b>
                                                                        </div>  
                                                            </div>
                                                    } return 
                                                    
                                                    })()
                                                }

                                                <div className="row">
                                                    <div className='col'>
                                                        {   
                                                            (() => {
                                                            if (value.producer) {
                                                            return <div className='mt-3 ml-3'>
                                                                    <b>Producer(s)</b> <br />
                                                                        <div className="factText p-1 rounded">{value.producer}</div>
                                                                    </div>
                                                            } 
                                                            return 
                                                            })()
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    (() => {
                                                    if (value.track_listing && !value.tracks_center) {
                                                        return <div className="row pt-3">
                                                                    <div className="col-12">
                                                                        <b>Track listing</b><br />
                                                                        <div className="bg-light">
                                                                            <div className="py-1 px-1 Description">
                                                                                <ReadMoreAndLess
                                                                                        charLimit={TracksCharLimit}
                                                                                        readMoreText={" Read more ▼"}
                                                                                        readLessText={" Read less ▲"}
                                                                                        readMoreClassName="read-more-less--more"
                                                                                        readLessClassName="read-more-less--less"
                                                                                    >
                                                                                    {value.track_listing}
                                                                                </ReadMoreAndLess>
                                                                        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                    return 
                                                    })()
                                                }




                                            </div>
                                            
                                            {/* 2 kolonne */}
                                            <div className="col-sm-5 pb-3 border-right">

                                                {value.main_comment &&
                                                    <div className="card bg-light px-2 pt-2 mt-1">
                                                        <div className="quote3 mb-3">{value.main_comment}</div> 
                                                        <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                                                    </div>
                                                }

                                                {value.comment &&
                                                    <div className="card bg-light py-2 px-2">
                                                        <div className="quote3 mb-3">{value.comment}</div> 
                                                        
                                                        {
                                                            (() => {
                                                                if (value.source_txt_details) {
                                                                    return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                                }
                                                                return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                            })()

                                                        }

                                                    </div>
                                                }
                                                {value.comment_more &&
                                                    <div className="card bg-light mt-2 mb-3 py-2 px-2">
                                                        <div className="quote3 mb-3">{value.comment_more}</div> 
                                                        <div className="blockquote-footer"><i>{value.source_more}</i></div>
                                                    </div>
                                                }

                                                {(value.track_listing && (value.tracks_center===true)) &&
                                                    <div className="row pt-3">
                                                        <div className="col-12">
                                                            <b>Track listing</b><br />
                                                            <div className="bg-light">
                                                                <div className="py-1 px-1 Description">
                                                                    <ReadMoreAndLess
                                                                            charLimit={TracksCharLimit}
                                                                            readMoreText={" Read more ▼"}
                                                                            readLessText={" Read less ▲"}
                                                                            readMoreClassName="read-more-less--more"
                                                                            readLessClassName="read-more-less--less"
                                                                        >
                                                                        {value.track_listing}
                                                                    </ReadMoreAndLess> 
                                                            
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                

                                                {
                                                    (() => {
                                                    if (value.video1) {
                                                        return <div>
                                                                    <div className="row mt-2">
                                                                        <div className='col-12'>
                                                                            <div className='videoTitle pl-2 mx-2' align='center'><strong>My selected tracks</strong></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                        <div className='col-sm-6 mb-2'>
                                                                            <div className="card bg-light p-2">
                                                                                <div className="mx-1">  
                                                                                    <ReactPlayer
                                                                                        url={ value.video1 }
                                                                                                light='true'
                                                                                                //light
                                                                                                controls
                                                                                                width="280"
                                                                                                height="200"
                                                                                    />
                                                                                </div>
                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                    <strong>{value.desc1}</strong>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                        <div className='col-sm-6 mb-2'>
                                                                            {
                                                                                (() => {
                                                                                if (value.video2) {
                                                                                    return <div className="card bg-light p-2">
                                                                                                <div className="mx-1">  
                                                                                                    <ReactPlayer
                                                                                                        url={ value.video2 }
                                                                                                                light='true'
                                                                                                                //light
                                                                                                                controls
                                                                                                                width="280"
                                                                                                                height="200"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                                    <strong>{value.desc2}</strong>
                                                                                                </div>
                                                                                            </div>
                                                                                }
                                                                                return 
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    }
                                                    return 
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                    if (value.video3) {
                                                        return <div>
                                                                    <div className="row mt-1">
                                                                        <div className='col-sm-6 mb-2'>
                                                                            <div className="card bg-light p-2">
                                                                                <div className="mx-1">  
                                                                                    <ReactPlayer
                                                                                        url={ value.video3 }
                                                                                                light='true'
                                                                                                //light
                                                                                                controls
                                                                                                width="280"
                                                                                                height="200"
                                                                                    />
                                                                                </div>
                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                    <strong>{value.desc3}</strong>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-sm-6 mb-2'>
                                                                            {
                                                                                (() => {
                                                                                if (value.video4) {
                                                                                    return <div className="card bg-light p-2">
                                                                                                <div className="mx-1">  
                                                                                                    <ReactPlayer
                                                                                                        url={ value.video4 }
                                                                                                                light='true'
                                                                                                                //light
                                                                                                                controls
                                                                                                                width="280"
                                                                                                                height="200"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='DescriptionBig2 pl-1'>
                                                                                                    <strong>{value.desc4}</strong>
                                                                                                </div>
                                                                                            </div>
                                                                                }
                                                                                return 
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                    return 
                                                    })()
                                                }

                                                

                                            </div>

                                            {/* 3 kolonne */}
                                            <div className="col-sm-4 pb-3">
                                                <div className="row">

                                                    <div className="col">
                                                        <div>
                                                            {
                                                                (() => {
                                                                    if (value.rd_text) {
                                                                        return <div><b>Recorded </b><br />
                                                                        <div className="DateTxt p-1">{value.rd_text}</div>
                                                                        
                                                                        </div>
                                                                        }
                                                                    if (value.recorded) {
                                                                    return <div><b>Recorded </b><br />
                                                                        <div className="Date p-1">{value.recorded}</div>
                                                                        
                                                                        </div>
                                                                    }
                                                                    
                                                                })()
                                                            }

                                                            {   
                                                                (() => {
                                                                if (value.studio_txt) {
                                                                return <div>
                                                                            <b>Location</b> <br />
                                                                            <div className="factText p-1">{value.studio_txt}</div>
                                                                            
                                                                        </div>
                                                                } 
                                                                return 
                                                                })()
                                                            }
                                                            {   
                                                                (() => {
                                                                if (value.engineer) {
                                                                return <div>
                                                                            <b>Engineer</b> <br />
                                                                            <div className="factText p-1">{value.engineer}</div>
                                                                            
                                                                        </div>
                                                                } 
                                                                return 
                                                                })()
                                                            }
                                                            
                                                            {
                                                                (() => {
                                                                if (value.album_released) {
                                                                return <div><b>Released</b> <br />
                                                                <div className="Date p-1">
                                                                    {value.album_released}</div></div>
                                                                } else if (value.ar_text) {
                                                                return <div><b>Released</b> <br />
                                                                <div className="Date p-1">
                                                                    {value.ar_text}</div></div>
                                                                }
                                                                })()
                                                            }
                                                            <div>
                                                            <b>Label</b> <br />
                                                            <div className="factText p-1">{value.label}</div>
                                                            </div>

                                                            {   
                                                                (() => {
                                                                if (value.genre) {
                                                                return <div>
                                                                        <b>Genre</b> <br />
                                                                        <div className="factText p-1">{value.genre}</div>
                                                                    </div>
                                                                } 
                                                                return 
                                                                })()
                                                            }

                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-12">
                                                        {(markdown_personnel || value.markdown_line_up) && line_up &&
                                                            <div>
                                                                <b>Line-up</b> <br />
                                                                <div className="factTextMarkdown p-1 rounded"> 
                                                                    <ReactMarkdown>
                                                                        {value.personnel}                    
                                                                    </ReactMarkdown> 
                                                                </div>
                                                            </div>
                                                        }

                                                        {(!markdown_personnel && !value.markdown_line_up) && line_up &&
                                                            <div><b>Line-up</b> <br />
                                                                <div className="factText p-1 rounded">  
                                                                <ReadMoreAndLess
                                                                        charLimit={LineUpCharLimit}
                                                                        readMoreText={" Read more ▼"}
                                                                        readLessText={" Read less ▲"}
                                                                        readMoreClassName="read-more-less--more"
                                                                        readLessClassName="read-more-less--less"
                                                                    >
                                                                    {value.personnel}
                                                                </ReadMoreAndLess>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col pt-3">
                                                            {
                                                                    (() => {
                                                                    if (value.comment_2) {
                                                                    return <div className="card bg-light py-2 px-2">
                                                                                <div className="quote3 mb-3">{value.comment_2}</div> 
                                                                                
                                                                                {
                                                                                    (() => {
                                                                                        if (value.source_txt_details_2) {
                                                                                            return <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                                                                        }
                                                                                        return <div className="blockquote-footer"><i>{value.source_txt_2}</i></div>

                                                                                    })()

                                                                                }

                                                                            </div>
                                                                    }
                                                                    return 
                                                                })()
                                                            }

                                                    </div>

                                                </div>
                                            
                                            </div>


                                        </div>
                                    </div>
                            })()
                        }

                    </div>
                }
                        
            </React.Fragment>          
        )
    })

    //REFERENCES
    const listReferences = sortedReferences.map(value => {
        return (
            <React.Fragment key={value.id}>

                {
                    (() => {
                    if (value.book_title) {
                    return  <div className="row px-1 my-1">
                                <div className="col">
                                    <div>{value.author_txt} ({value.published_year}).
                                    <a href={value.more_info}> {value.book_title}</a>. {value.publisher}. (book) </div>
                                </div>
                            </div>
                    } if (value.person && !value.date_txt) {
                    return <div className="row px-1 my-1">
                            <div className="col">
                                <a href={value.url}>
                                    {value.title}
                                </a> ({value.person})
                            </div>
                        </div>
                    } if (!value.person && value.date_txt) {
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a> ({value.date_txt})
                                    </div>
                                </div>
                    }
                    if (!value.person && !value.date_txt) {
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a> 
                                    </div>
                                </div>
                    }
                    return <div className="row px-1 my-1">
                                <div className="col">
                                    {value.person} ({value.date_txt}). <a href={value.url}>
                                        {value.title}
                                    </a>
                                </div>
                            </div>
                    })()
                }

                
            </React.Fragment>
        )
    })

    return (
        <main className="my-1 py-1">
            {list.length > 0 && (
                <div className="container">
                
                    <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={picture_link}
                    url={'https://www.musicthisday.com/'+page}
                    canonical={'https://www.musicthisday.com/'+page}
                    type='article' />

                    <div className="row">
                        <div className="col">
                            {listHeading}
                        </div>
                    </div>

                    {!AgGrid && 
                    <div className="row">
                        <div className="col">

                            {
                                (() => {
                                if (albums.length > 0) {
                                return <div> { albumItems } </div>
                                }
                                return <div>
                                        <h3>No albums found</h3>

                                    </div>
                                })()
                            }

                        </div>
                    </div>
                    }

                    {AgGrid && 
                    <div className="row pr-2 pl-2 pt-3">
                        <div id="myGrid" style={{ height: '2000px', width: '1200px' }} className="ag-theme-balham">
                            <AgGridReact
                                rowSelection="multiple"
                                pagination={true}
                                rowData={filterArray}
                                columnDefs={columnDefs}>
                            </AgGridReact>
                        </div>
                    </div>
                    }

                    <div align="Right">
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </div>



                    {
                        (() => {
                        if (references.length > 0) {
                        return  <div>
                                        <div className="row mt-4">
                                            <div className="col">
                                                    <h4>References & similar lists</h4>
                                            </div>
                                        </div>
                                        <div className="row px-1">
                                            <div className="col">
                                                <b>
                                                <a href='https://en.wikipedia.org'>
                                                    Wikipedia
                                                </a>
                                                </b>
                                            </div>
                                        </div>
                                    
                                        <div className="row mt-2">
                                            <div className="col">
                                                    { listReferences }
                                            </div>
                                        </div>
                                </div>
                        }
                        return 
                        })()
                    }



                </div>
            )}
        </main>
    )



}

export default AlbumLists